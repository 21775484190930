<template>
  <div>
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="10">
        <b-row>
          <b-col sm="12" lg="12"><strong>Font Awesome</strong></b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="3" v-for="(fa, fa_index) in d_faIcon" style="border: solid 1px grey;">
            <b-row>
              <b-col sm="12" lg="3">
                <i :class="'fa ' + fa" style="font-size: 20px;"></i>
              </b-col>
              <b-col sm="12" lg="9">
                {{ fa }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import ProductPresentationService from '@/services/product_presentation';
import { data as usr_shp } from '@/options/usr_shp';
import { data as fa_icon } from '@/options/fa_icon';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'icon_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_productList: '',
      d_usrShpWdm: usr_shp,
      d_faIcon: fa_icon,
    };
  },
  created: function () {
    // this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_bundleAndProductListActive();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpWdm);
  },
  methods: {
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShop: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_record'), query: {} });
    },
    f_newUserShopBundle: function (user_shop_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_record'), query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_goToBundleDetails: function (user_shop_client_id, user_shop_bundle_client_id) {
      this.$router.push({ path: this.f_getRoutePath('product_details'), query: { 'user_shop_client_id': user_shop_client_id, 'user_shop_bundle_client_id': user_shop_bundle_client_id } });
    },
    f_userShopBundleList: function (user_shop_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_list'), query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_editThisUserShop: function (user_shop_client_id) {
      let route_path = this.f_getRoutePath('user_shop_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_searchUserShop: function (usr_shp) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_bundleAndProductListActive: function () {
      let query = '';
      let data = {};
      ProductPresentationService.bundle_and_product_list_active(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_productList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

<style type="text/css">
.product-card {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(90deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  border: none;
  box-shadow: 0px 0px 3px 3px #bcb1b1;
}  
.product-card:hover {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(120deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  margin: -3px;
  border: none;
  box-shadow: 0px 0px 10px 10px #bcb1b1;
}  
</style>

